import Text from '../components/Text/Text'
import Default from './../layouts/Default/Default'

const NotFoundPage = () => (
  <Default layout="web" navbar="web" withFooter withFab>
    <Text heading1 as="h1">
      Ooops! 404 NOT FOUND
    </Text>
    <Text medium as="p">
      You just hit a route that doesn&#39;t exist... the sadness :(
    </Text>
  </Default>
)

export default NotFoundPage
